 import React, {useState} from "react";
 import ReactWOW from "react-wow";
 import {logFormSubmission} from "../../../../utils/google-tag-manager";

 export const CHItalianRegistration = ({hubspotUrl, title, subTitle}) => {
     const [firstname, setFirstname] = useState("");
     const [lastname, setLastname] = useState("");
     const [email, setEmail] = useState("");
     const [company, setCompany] = useState("");
     const [phone, setPhone] = useState("");
     const [companyRegistrationNumber, setCompanyRegistrationNumber] = useState("");
     const [website, setWebsite] = useState("");
     const [submitted, setSubmitted] = useState(false);
     const [error, setError] = useState(undefined);

     const handleSubmit = async (evt) => {

         evt.preventDefault();
         const data = {
             "fields": [
                 {
                     "name": "firstname",
                     "value": firstname
                 },
                 {
                     "name": "lastname",
                     "value": lastname
                 },
                 {
                     "name": "email",
                     "value": email
                 },
                 {
                     "name": "phone",
                     "value": phone
                 },
                 {
                     "name": "company",
                     "value": company
                 },
                 {
                     "name": "company_registration_number",
                     "value": companyRegistrationNumber
                 },
                 {
                     "name": "website",
                     "value": website
                 }
             ],
             "context": {
                 // "pageUri": "http://localhost:8000",
                 // "PageName": "Product Page Australia",
                 // "skipValidation": true
             },
         };

         const response = await fetch(hubspotUrl, {
             body: JSON.stringify(data),
             method: "POST",
             headers: {
                 'Content-Type': 'application/json'
             }
         });

         if (response.status === 200) {
             setSubmitted(true);
             logFormSubmission('partner-registration');
         } else if (response.status === 400) {
            setError('Inserire un indirizzo e-mail valido.')
        }
     }

     return (
         <section className="contactus-area">
             <div className="container">
                 <div className="">
                     <div className="text-center text-lg-start ps-0">
                         <ReactWOW delay='.1s' animation='fadeInLeft'>
                             <h2 className='display-6 contact-us-heading'>{subTitle}</h2>
                             <p className='contactus-text'>{title}</p>
                         </ReactWOW>
                     </div>
                 </div>

                 <div className='row'>
                     <div className="col-12 col-lg-8 getintouch-form">

                         {submitted ? <h4 className='px-5 success-message-form'>Grazie per aver registrato i tuoi dati. Ti contatteremo presto!</h4> :

                             <form onSubmit={handleSubmit}>
                                 <div className="row ">
                                     <h4 className='pb-4 font-medium'>Dettagli del contatto</h4>
                                     <div className="col-12 col-lg-6 mx-auto">
                                         <div className="form-group">
                                             <input
                                                 type="text"
                                                 className="form-control"
                                                 id="your_firstname"
                                                 placeholder="Nome di battesimo *"
                                                 value={firstname}
                                                 required
                                                 onChange={e => setFirstname(e.target.value)}
                                             />
                                         </div>
                                     </div>

                                     <div className="col-12 col-lg-6 mx-auto">
                                         <div className="form-group">
                                             <input
                                                 type="text"
                                                 className="form-control"
                                                 id="your_lastname"
                                                 placeholder="Cognome *"
                                                 value={lastname}
                                                 required
                                                 onChange={e => setLastname(e.target.value)}
                                             />
                                         </div>
                                     </div>
                                     <div className="col-12 col-lg-6 mx-auto">
                                         <div className="form-group">
                                             <input
                                                 type="email"
                                                 className="form-control"
                                                 id="your_email"
                                                 placeholder="E-mail *"
                                                 value={email}
                                                 required
                                                 onChange={e => setEmail(e.target.value)}
                                             />
                                         </div>
                                     </div>
                                     <div className="col-12 col-lg-6 mx-auto">
                                         <div className="form-group">
                                             <input
                                                 type="tel"
                                                 minLength={7}
                                                 maxLength={20}
                                                 className="form-control"
                                                 id="your_number"
                                                 placeholder="Telefono"
                                                 value={phone}
                                                 onChange={e => setPhone(e.target.value)}
                                             />
                                         </div>
                                     </div>
                                 </div>
                                 <div className="row">
                                     <h4 className='pb-4 pt-4 font-medium'>Dettagli della Compagnia</h4>
                                     <div className="col-12 col-lg-6 mx-auto">
                                         <div className="form-group">
                                             <input
                                                 type="text"
                                                 className="form-control"
                                                 id="company"
                                                 placeholder="Azienda *"
                                                 value={company}
                                                 required
                                                 onChange={e => setCompany(e.target.value)}
                                             />
                                         </div>
                                     </div>
                                     <div className="col-12 col-lg-6 mx-auto">
                                         <div className="form-group">
                                             <input
                                                 type="text"
                                                 className="form-control"
                                                 id="company_registration_number"
                                                 placeholder="Numero di registrazione della società *"
                                                 value={companyRegistrationNumber}
                                                 required
                                                 onChange={e => setCompanyRegistrationNumber(e.target.value)}
                                             />
                                         </div>
                                     </div>
                                     <div className="col-12 col-lg-12 mx-auto">
                                         <div className="form-group">
                                             <input
                                                 type="text"
                                                 className="form-control"
                                                 id="website"
                                                 placeholder="URL del Sito"
                                                 value={website}
                                                 onChange={e => setWebsite(e.target.value)}
                                             />
                                         </div>
                                     </div>
                                 </div>

                                 <div className="text-lg-start text-center pt-1 pb-4 mx-auto">
                                     <div className="text-danger pb-4">
                                         {error}
                                     </div>
                                     <button type="submit" className="default-btn-black font-medium">
                                         SOTTOSCRIVI
                                         <span></span>
                                     </button>
                                 </div>
                             </form>
                         }
                     </div>

                 </div>
             </div>
         </section>
     )

 }