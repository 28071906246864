import React, {useState} from "react";
import ReactWOW from "react-wow";
import {logFormSubmission} from "../../../../utils/google-tag-manager";

export const AuRegistration = ({hubspotUrl, title, subTitle}) => {
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [au_business_location, setBusinessLocation] = useState("");
    const [phone, setPhone] = useState("");
    const [companyacn, setCompanyAcn] = useState("");
    const [your_profession, setProfession] = useState("");
    const [acl_number, setAclnumber] = useState("");
    const [aggregator, setAggregator] = useState("");
    const [crn, setCrn] = useState("");
    const [website, setWebsite] = useState("");
    const [linkedin, setLinkedin] = useState("");
    // const [policy, setPolicy] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(undefined);

    const handleSubmit = async (evt) => {

        evt.preventDefault();
        const data = {
            "fields": [
                {
                    "name": "firstname",
                    "value": firstname
                },
                {
                    "name": "lastname",
                    "value": lastname
                },
                {
                    "name": "email",
                    "value": email
                },
                {
                    "name": "phone",
                    "value": phone
                },
                {
                    "name": "jobtitle",
                    "value": your_profession
                },
                {
                    "name": "linkedin_bio",
                    "value": linkedin
                },
                {
                    "name": "company",
                    "value": company
                },
                {
                    "name": "au_business_location",
                    "value": au_business_location
                },
                {
                    "name": "company_registration_number",
                    "value": companyacn
                },
                {
                    "name": "acl_number",
                    "value": acl_number
                },
                {
                    "name": "aggregator_name",
                    "value": aggregator
                },
                {
                    "name": "credit_representative_numbers",
                    "value": crn
                },
                {
                    "name": "website",
                    "value": website
                }
            ],
            "context": {
                // "pageUri": "http://localhost:8000",
                // "PageName": "Product Page Australia",
                // "skipValidation": true
            },
        };

        const response = await fetch(hubspotUrl, {
            body: JSON.stringify(data),
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (response.status === 200) {
            setSubmitted(true);
            logFormSubmission('partner-registration');
        } else if (response.status === 400) {
            setError('Please provide a valid email address.')
        }
    }

    return (
        <section className="contactus-area">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center text-lg-start ps-md-0 ">
                        <ReactWOW delay='.1s' animation='fadeInLeft'>
                            <h2 className='display-6 contact-us-heading'>{subTitle}</h2>
                            <p className='contactus-text'>{title}</p>
                        </ReactWOW>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-12 col-lg-8 getintouch-form">

                        {submitted ? <h4 className='px-5 success-message-form'>Thanks for registering your details. We'll get in touch with you soon!</h4> :

                            <form onSubmit={handleSubmit}>
                                <div className="row ">
                                    <h4 className='pb-4 font-medium'>Contact details</h4>
                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="your_firstname"
                                                placeholder="First name *"
                                                value={firstname}
                                                required
                                                onChange={e => setFirstname(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="your_lastname"
                                                placeholder="Last name *"
                                                value={lastname}
                                                required
                                                onChange={e => setLastname(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="your_email"
                                                placeholder="Email *"
                                                value={email}
                                                required
                                                onChange={e => setEmail(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="tel"
                                                minLength={7}
                                                maxLength={20}
                                                className="form-control"
                                                id="your_number"
                                                placeholder="Phone"
                                                value={phone}
                                                onChange={e => setPhone(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <select
                                                type="text"
                                                className="form-control"
                                                id="your_profession"
                                                placeholder="Profession *"
                                                value={your_profession}
                                                required
                                                defaultValue=""
                                                onChange={e => setProfession(e.target.value)}
                                            >
                                                <option value="" disabled selected className="d-none">Profession *</option>
                                                <option value="Accountant">Accountant</option>
                                                <option value="Broker">Broker</option>
                                                <option value="Advisor">Financial Advisor</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="linkedin"
                                                placeholder="Linkedin bio"
                                                value={linkedin}
                                                onChange={e => setLinkedin(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <h4 className='pb-4 pt-4 font-medium'>Company details</h4>
                                    <div className="col-12 col-lg-12 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="company"
                                                placeholder="Company *"
                                                value={company}
                                                required
                                                onChange={e => setCompany(e.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <select
                                                type="text"
                                                className="form-control"
                                                id="au_business_location"
                                                placeholder="Business location *"
                                                value={au_business_location}
                                                required
                                                defaultValue=""
                                                onChange={e => setBusinessLocation(e.target.value)}
                                            >
                                                <option value="" disabled selected className="d-none">Business location *</option>
                                                <option value="VIC">VIC</option>
                                                <option value="QLD">QLD</option>
                                                <option value="SA">SA</option>
                                                <option value="NSW">NSW</option>
                                                <option value="WA">WA</option>
                                                <option value="TAS">TAS</option>
                                                <option value="NT">NT</option>
                                                <option value="ACT">ACT</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="companyacn"
                                                placeholder="Company ACN or ABN *"
                                                value={companyacn}
                                                required
                                                onChange={e => setCompanyAcn(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="acl_number"
                                                placeholder="ACL Number"
                                                value={acl_number}
                                                onChange={e => setAclnumber(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="aggregator"
                                                placeholder="Name of Aggregator"
                                                value={aggregator}
                                                onChange={e => setAggregator(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="crn"
                                                placeholder="Credit Representative Number(s)"
                                                value={crn}
                                                onChange={e => setCrn(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-6 mx-auto">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="website"
                                                placeholder="Website URL"
                                                value={website}
                                                onChange={e => setWebsite(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="text-lg-start text-center pt-1 pb-4 mx-auto">
                                    <div className="text-danger pb-4">
                                        {error}
                                    </div>
                                    <button type="submit" className="default-btn-black font-medium">
                                        SUBMIT
                                        <span></span>
                                    </button>
                                </div>
                            </form>
                        }
                    </div>

                </div>
            </div>
        </section>
    )

}