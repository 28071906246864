import React from "react";
import Layout from "../components/global/Layout";
import Above from "../components/global/GenericBanner";
import PartnerRegistration from "../components/global/forms/PartnerRegistration";
import { graphql } from "gatsby";
import { parseSeoFromQuery } from "../components/global/SEO";

const Introducers = props => {
  return (
    <Layout {...parseSeoFromQuery(props.data)}>
      <Above {...props?.data?.markdownRemark?.frontmatter} />
      <PartnerRegistration {...props?.data?.markdownRemark?.frontmatter} />
    </Layout>
  );
};

export const query = graphql`
  query ($page: String, $locale: String) {
    markdownRemark(
      frontmatter: { page: { eq: $page }, locale: { eq: $locale } }
    ) {
      frontmatter {
        ...seo
        whiteTitle
        greenTitle
        formTitle
        formSubTitle
        locale
        hubspotUrl
      }
    }
  }
`;

export default Introducers;
