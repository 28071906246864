import React from 'react'
import {AuRegistration} from "./PartnerRegistration/au-en";
import {CHGermanRegistration} from "./PartnerRegistration/ch-de";
import {CHEnglishRegistration} from "./PartnerRegistration/ch-en";
import {CHFrenchEnglish} from "./PartnerRegistration/ch-fr";
import {CHItalianRegistration} from "./PartnerRegistration/ch-it";
import {DutchRegistration} from "./PartnerRegistration/nl-nl";
import {UkRegistration} from "./PartnerRegistration/uk-en";

const PartnerRegistration = (props) => {
    const {locale, hubspotUrl, formTitle, formSubTitle} = props;

    switch (locale){
        case "au-en":
            return <AuRegistration hubspotUrl={hubspotUrl} title={formTitle} subTitle={formSubTitle} />
        case "ch-de":
            return <CHGermanRegistration hubspotUrl={hubspotUrl} title={formTitle} subTitle={formSubTitle} />
        case "ch-en":
            return <CHEnglishRegistration hubspotUrl={hubspotUrl} title={formTitle} subTitle={formSubTitle} />
        case "ch-fr":
            return <CHFrenchEnglish hubspotUrl={hubspotUrl} title={formTitle} subTitle={formSubTitle} />
        case "ch-it":
            return <CHItalianRegistration hubspotUrl={hubspotUrl} title={formTitle} subTitle={formSubTitle} />
        case "nl-nl":
            return <DutchRegistration hubspotUrl={hubspotUrl} title={formTitle} subTitle={formSubTitle} />
        case "uk-en":
        default:
            return <UkRegistration hubspotUrl={hubspotUrl} title={formTitle} subTitle={formSubTitle} />
    }
}

export default PartnerRegistration